@import	"_kit/normalise", "_kit/resets-helpers"; // Standardise elements

// Our variables
$base-font-family: "neue-haas-grotesk-text", Helvetica, Arial, sans-serif;
$base-font-size:	2rem;
$base-font-weight:	400;

$heading-font: "neue-haas-grotesk-display", "Helvetica Bold", "Arial Black", sans-serif;

$base-line-height:	1.4;

$base-text-color:	#000;
$divide-background: #000;
$spot-color: red;
$muted-color: #e2e2e2;
$background-color: white;

$header-height: 10.8rem;
$content-width: 138rem;

$active: #4AA7A8;

html,
body {
	padding: 0;
	margin: 0;
	position: relative;
  height: 100%;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	background: $background-color;
	font-size: $base-font-size;
	color: $base-text-color;
	line-height: $base-line-height;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
	padding-top: $header-height;

	&.navOpen {
		#global-nav-trigger {
			transform: rotate(0deg);
			// transition: transform 0.5s;
		}
		.global-nav {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 1;
		}
	}

	&.headerSlimmed {

		.global-header{

			&__text {

				@media only screen and (min-width:730px) {
					font-size: 4.4rem;
				}

			}

			&__subtext {
				// opacity: 1;
				top: 5.4rem;
			}

		}

		.back-to-top {
			opacity: 1;
			visibility: visible;
		}

	}
}

@keyframes reduceHeading {
  from {
    font-size: 8rem;
  }

  to {
    font-size: 4.4rem;
  }
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4 {
	font-weight: bold;
	line-height: 1em;
	padding-bottom: 0.35em;

	& + p {
		margin-top: 0;
	}

}

h1, .h1 {
	font-size: 4.4rem;
}

.h1, .h2.global-header__subtext {
	font-family: $heading-font;
	letter-spacing: -0.5px;
	font-weight: normal;
}


.global-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: white;
	height: $header-height;
	z-index: 100;

	&__wrapper {
		position: relative;
		width: 100%;
		max-width: $content-width;
		margin: 0 auto;
	}

	&__text {
		position: absolute;
		top: 3.2rem;
		left: 1.2rem;
		font-size: 3.6rem;
		line-height: 1em;
		transition: all 0.5s;

		@media only screen and (min-width:440px) {
			left: 2.2rem;
		}

		@media only screen and (min-width:730px) {
			top: 0.8rem;
			font-size: 8rem;
		}

		a {
			color: $base-text-color;
		}
	}

	&__subtext {
		font-size: 4.4rem;
		color: $spot-color;
		position: absolute;
		// top: 5.4rem;
		top: 10.8rem;
		left: 2.2rem;
		right: 0;
		// opacity: 0;
		transition: all 0.5s;
		z-index: -1;
		background: white;
		visibility: hidden;

		a {
			color: $spot-color;
		}

		@media only screen and (min-width:730px) {
			visibility: visible;
		}
	}
}

.small-screen-heading {
	color: $spot-color;
	display: block;
	@media only screen and (min-width:730px) {
		display: none;
	}
}

#global-nav-trigger {
	display: block;
	position: fixed;
	top: 0;
	right: 1.2rem;
	font-size: 10rem;
	color: $base-text-color;
	line-height: 1em;
	margin: 0;
	padding: 0;
	transform: rotate(45deg);
	z-index: 500;
	transition: transform 0.5s;
	cursor: pointer;

	@media only screen and (min-width:440px) {
		right: 2.4rem;
	}
}

.global-nav {
	display: block;
	position: fixed;
	bottom: -100vh;
	background: $spot-color url(#) no-repeat top right;
	background-size: auto 100%;
	z-index: 250;
	padding: 2.4rem 1.2rem;
	display: flex;
	align-items: flex-end;
	opacity: 0;
	transition: opacity 0.5s;

	@media only screen and (min-width:440px) {
		padding: 2.4rem;
	}

	&__title {
		color: #111;
		display: block;
		font-size: 6.4rem;
		font-weight: bold;
		padding-bottom: 9.2rem;
		line-height: 1em;
		letter-spacing: -0.5px;

		@media only screen and (min-width:440px) {
			font-size: 8rem;
		}
	}

	ul, li {
		margin: 0;
		padding: 0;
	}

	li {
		line-height: 2em;
	}

	a {
		font-size: 3.1rem;
		letter-spacing: -0.5px;
		font-weight: bold;
		color: $muted-color;

		@media only screen and (min-width:525px) {
			font-size: 4.4rem;
		}
	}

}

.global-main {
	padding: 2.4rem 1.2rem;
	width: 100%;
	max-width: $content-width;
	margin: 0 auto;

	@media only screen and (min-width:440px) {
		padding: 2.4rem;
	}

	@media only screen and (min-width:730px) {
		padding: 19.2rem 2.4rem 2.4rem 2.4rem !important;
	}

}

.sticky {

	figure {
		margin-top: 0 !important;
	}
	@media only screen and (min-width:970px) {
		position: sticky;
		top: $header-height;
	}

}

.article-index {

	ul {
		margin-bottom: 0 !important;
	}

	li {
		padding-bottom: 0.8rem;

		&:last-of-type {
			padding-bottom: 0;
		}
	}


		&__right {
			padding-top: 2.6rem;
			display: block;
			@media only screen and (min-width:970px) {
				display: none;
			}
		}



	&__left {
		padding-top: 2.6rem;
		padding-left: 4.8rem;
		display: none;
		@media only screen and (min-width:970px) {
			display: block;
		}
	}
}

article.index, article.post {
	// padding-top: 9.6rem;
	padding-bottom: 9.6rem;
}

article.post {

	h1 {
		color: $spot-color;
	}

	figure {
		margin: 4.8rem 0;

		.portrait-wrapper {
			max-height: 50rem;
			border-bottom: 0.6rem solid #000;
			background: $muted-color;

			img {
				max-height: 100%;
				width: auto;
				max-width: 100%;
				border: 0;
				margin: 0 auto;
			}
		}

		figcaption {
			border-bottom: 0.1rem solid $divide-background;
			padding-bottom: 2rem;
		}
	}

	h1 + figure {
		margin-top: 0;
	}

}

.book-info {
	width: 100%;
	max-width: 31.5rem;
	border-top: 0.6rem solid black;
	font-size: 1.8rem;

	&__section {
		padding-top: 0.6rem;
		padding-bottom: 1.8rem;
		border-bottom: 0.1rem solid black;

		strong, span {
			display: block;
		}

		&:last-of-type {
			border: 0;
		}

	}

	&__lead {
		display: none;
		@media only screen and (min-width:970px) {
			display: block;
		}
	}

	&__follow {
		margin-top: 4.8rem;
		display: block;
		@media only screen and (min-width:970px) {
			display: none;
		}
	}

}

.copy-block {
	width: 100%;
	@media only screen and (min-width:970px) {
		display: flex;
	}

	&--pair {

		.copy-block__content {
			position: relative;
			width: 100%;

			@media only screen and (min-width:970px) {
				width: 50%;
			}

			&:first-of-type {
				padding-bottom: 2.4rem;
				@media only screen and (min-width:970px) {
					padding-bottom: 0;
					padding-right: 1.2rem;
				}
			}
			&:last-of-type {
				@media only screen and (min-width:970px) {
					padding-left: 1.2rem;
				}
				position: relative;
				padding-top: 1.8rem;

				&:before {
					content:'';
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					height: 1.6rem;
					background: $divide-background;
					@media only screen and (min-width:970px) {
						left: 1.2rem;
					}
				}
			}
		}

	}

	&__content {

		h1 {
			padding-top: 2rem;
			padding-bottom: 4.4rem;
		}

		h2 {
			font-size: 3.2rem;
		}

		img {
			display: block;
			width: 100%;
		}

		a {
			color: $base-text-color;
			font-weight: bold;
			opacity: 1;
			transition: opacity, 0.3s;

			&:before {
				content:"";
				display: inline-block;
				width: 0;
			  height: 0;
			  border-top: 7px solid transparent;
			  border-bottom: 7px solid transparent;
			  border-left: 10px solid $spot-color;
				padding-right: 0.4rem;
			}

			&:hover {
				opacity: 0.5;
			}

		}

		h2, h3 {
			font-size: 2.4rem;
		}

		figure {
			margin: 0;
			margin-bottom: 4.8rem;

			img {
				border-bottom: 0.6rem solid $divide-background;

				&.no-bottom {
					border: 0;
					margin-bottom: 3.8rem;
				}
			}

			figcaption {
				padding-top: 1.6rem;
				font-weight: bold;
				font-size: 1.8rem;
				line-height: 2.4rem;

				strong {
					display: block;
					color: $spot-color;
				}
			}
		}

		ul {
			list-style: disc;
			margin: 1em 0 1em 2.4rem;
		}

		p:last-of-type {
			margin-bottom: 0;

			&:after {
				content:'';
				display: inline-block;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 50%;
				background: $base-text-color;
				margin-left: 0.4rem;
			}
		}

		.copy-block__links {
			list-style-type: none;
			margin: 1em 0;
		}

	}

}

.section-heading {
	margin-top: 4.8rem;
	border-top: 0.6rem solid $divide-background;
	padding-top: 1.8rem;
	padding-bottom: 3.2rem;
	border-bottom: 1px solid $divide-background;
}

.reviews {

	blockquote {
		margin-left: 0;
		margin-right: 0;

		p:last-of-type {
			margin-bottom: 0;
			&:after {
				display: none;
			}
		}

		cite {
			font-style: normal;
			font-weight: bold;
		}
	}
}

.footnotes {
	font-size: 1.6rem;
	ol {
		padding-left: 2.4rem;
	}
	li {
		margin-bottom: 1em;
	}

	a {
		&:before {
			border: 0;
			padding: 0;
			display: inline;
		}
	}
}

a.footnote-link {
	color: $spot-color;
	&::before {
		border: 0;
		padding: 0;
		display: inline;
	}
}

a.footnoteBack {
	display: inline-flex;
	vertical-align: middle;
	justify-content: flex-start;
	margin-left: -2px;

	svg {
		width: 16px;
		height: 16px;
	}
}

.further-links {

	&__heading {
		width: 100%;
		font-size: 2rem;
		color: $spot-color;
		border-top: 0.2rem solid $divide-background;
		padding: 1.2rem 0 3.2rem 0;
	}

	@media only screen and (min-width:970px) {
	display: flex;
	flex-wrap: wrap;

		&__column {
			width: 50%;

			&:first-of-type {
				padding-right: 1.2rem;
			}
			&:last-of-type {
				padding-left: 1.2rem;
			}
		}

		&--hero {
			padding-right: 1.2rem;

			.further-link-promo {
				min-height: 65.4rem;
				background-position: center center;

				&__text {
					padding-right: 1.2rem;
				}

				&__img {
					display: none;
				}
			}
		}

	}

}

.further-link-promo {
	display: block;
	border-top: 1.6rem solid $divide-background;
	background-color: $muted-color;
	position: relative;
	margin-bottom: 2.4rem;
	background-size: 0% 0%;
	background-position: bottom center;
	background-repeat: no-repeat;
	opacity: 1;
	transition: opacity 0.3s;
	width: 100%;

	@media only screen and (min-width:680px) {
		min-height: 31.5rem;
	}

	@media only screen and (min-width:970px) {
		background-size: 100% auto;
	}

	&:hover {
		opacity: 0.5;
	}

	&__text {
		padding: 1.2rem;
		padding-bottom: 0;
		color: $base-text-color;

		@media only screen and (min-width:680px) {
			padding-bottom: 1.2rem;
			padding-right: calc(50% + 1.2rem);
		}

	}

	&__img {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		width: 100%;
		padding-top: 100%;

		@media only screen and (min-width:680px) {
			width: auto;
			padding-top: 0;

			position: absolute;
			top: 0;
			right: 0;
			left: 50%;
			bottom: 0;
		}

	}

	h2 {
		color: $spot-color;
		font-size: 2rem;
		padding: 0;
		line-height: 1.4em;
	}

	strong {
		display: block;
	}

}

.anchor.prep, h2.prep {
	display: none;
}

.listings {

	h1 {
		color: $spot-color;
	}

	&-filter {
		margin: 0 0 9.6rem 0;
		padding-top: 2.4rem;
		border-top: 1.6rem solid $divide-background;

		.prep {
			display: none;
		}

		a {
			font-size: 4.4rem;
			font-weight: bold;
			line-height: 1em;
			letter-spacing: -0.5px;
			color: $base-text-color;
			opacity: 1;
			transition: opacity 0.3s;

			&:hover {
				opacity: 0.5;
			}

			&.active-filter {
				color: $spot-color;

				&:hover {
					opacity: 1;
					cursor: default;
				}
			}
		}
	}

	h2 {
		border-top: 2px solid $divide-background;
		padding-top: 0.8rem;
		padding-bottom: 2.4rem;
		color: $spot-color;
	}

	&-grid {
		display: flex;
		flex-wrap: wrap;
	}

	&-item {
		border-top: 1.6rem solid $divide-background;
		color: $base-text-color;
		margin-bottom: 4.8rem;
		width: 100%;
		opacity: 1;
		transition: opacity 0.5s;

		@media only screen and (min-width:680px) {
			width: calc((100% - 2.4rem) / 2);
			margin-bottom: 9.6rem;

			&:nth-child(odd){
				margin-right: 1.2rem;
			}
			&:nth-child(even){
				margin-left: 1.2rem;
			}
		}

		@media only screen and (min-width:1024px) {
			width: calc((100% - 4.8rem) / 3);

			&:nth-child(odd), &:nth-child(even){
				margin-left: 0;
				margin-right: 0;
			}

			&:nth-child(3n-1){
				margin-left: 2.4rem;
				margin-right: 2.4rem;
			}

		}

		h3 {
			font-size: 2rem;
			line-height: 1.4;
			letter-spacing: normal;
		}

		img {
			display: block;
			width: 100%;
			margin-bottom: 2.4rem;
		}

		&:hover {
			opacity: 0.5;
		}

		&__locked {
			&:hover {
				opacity: 1;
				cursor: default;
			}
		}

	}

}

.blog-posts {

	h1 {
		color: $spot-color;
	}

	.post-list-item {
		display: block;
		border-top: 1.6rem solid $divide-background;
		background: $muted-color;
		margin-bottom: 4.8rem;
		opacity: 1;
		transition: opacity 0.3s;

		@media only screen and (min-width:970px) {
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			height: calc(25rem + 1.6rem);
		}

		img {
			display: block;
			width: 100%;

			@media only screen and (min-width:970px) {
				width: 400px;
			}

		}

		&--introduction {
			background: white;
			border-bottom: 1px solid $divide-background;

			img {
				display: none;

				@media only screen and (min-width:970px) {
					display: block;
					visibility: hidden;
				}
			}

			.post-list-item {

				&__content {
					border-bottom-color: transparent;
					&:after {
						background-image: linear-gradient(rgba(255,255,255,0), white);
					}
				}

			}

		}

		&__content {
			position: relative;
			padding: 2.4rem;
			height: calc(30rem - 4.2rem);
			overflow: hidden;
			border-bottom: 4.8rem solid $muted-color;

			@media only screen and (min-width:970px) {
				border: 0;
				height: calc(25rem - 4.2rem);
				max-width: calc(65.4rem + 2.4rem);
				padding: 1.8rem 2.4rem;
			}

			&:after {
				display: block;
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 6.4rem;
				background-image: linear-gradient(rgba(255,255,255,0), $muted-color);
			}

			h2 {
				color: $spot-color;
			}

			p {
				position: relative;
				color: $base-text-color;
				overflow: hidden;
			}
		}

		&:hover {
			opacity: 0.5;
		}
	}

}

.end-links {
	margin-bottom: 9.6rem;

	@media only screen and (min-width:680px) {
		display: flex;
		justify-content: space-between;
	}

	.end-link {
		width: 100%;

		@media only screen and (min-width:680px) {
			width: calc(50% - 1.2rem);
		}

		a {
			display: block;
			font-size: 4.4rem;
			color: $base-text-color;
			position: relative;
			opacity: 1;
			transition: opacity 0.3s;

			&:after {
				position: absolute;
				content:'';
				display: block;
				width: 5.4rem;
				height: 5.4rem;
				background: transparent url('/assets/back-to-top.svg') no-repeat center center;
				top: -0.5rem;
			}

			&:hover {
				opacity: 0.5;
			}

		}

		&--next a:after {
			right: -0.8rem;
			transform: rotate(90deg);
		}
	}
}

.post-back-link {
	display: none;
	position: relative;
	opacity: 1;
	transition: opacity 0.3s;
	margin-top: 3.2rem;
	text-indent: -999rem;
	overflow: hidden;

	@media only screen and (min-width:970px) {
		display: block;
	}

	&:before {
		display: none !important;
	}

	&:after {
		position: absolute;
		content:'';
		display: block;
		width: 5.4rem;
		height: 5.4rem;
		background: transparent url('/assets/back-to-top.svg') no-repeat center center;
		top: 0.5rem;
		transform: rotate(-90deg);
	}

	&:hover {
		opacity: 0.5;
	}
}

.anchor {
	display: block;
	position: relative;
	top: -$header-height - 0.5rem;
	visibility: hidden;
}

.back-to-top {
	width: 5.4rem;
	height: 5.4rem;
	display: block;
	position: fixed;
	bottom: 2.4rem; // 6.4 when at end of page
	right: 1.2rem;
	color: $base-text-color;
	background: rgba(255,255,255,0.4);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s;
	background: rgba(255,255,255,0.4) url('/assets/back-to-top.svg') no-repeat center center;
	// background-size: 80rem 72rem;

	@media only screen and (min-width:440px) {
		right: 2.4rem;
	}

	&:before {
		content: '';
		position: absolute;
		left: 1.2rem;
		bottom: -2.1rem;
	}
}

.contact {

	.copy-block--pair .copy-block__content {

		&:first-of-type {
			padding-top: 0.9rem;
			font-weight: bold;
			color: $spot-color;

			&:before {
				content: '';
		    display: block;
		    position: absolute;
		    top: 0;
		    right: 1.2rem;
		    left: 0;
		    height: 1.6rem;
		    background: #000;
			}

		}

	}

	p:last-of-type {

		&:after {
			display: none;
		}

	}

	.global-form-item:first-of-type {
		border-top: 0;
	}

	.form-action {
		transition: opacity 0.3s;

		&:hover {
			opacity: 0.5;
		}
	}

	&-disclaimer {
		font-size: 1.2rem;
		opacity: 0.6;
		margin: 2.4rem 0;

		a {
			&:before {
				display: none;
			}
		}
	}

}

.global-form-item {
	display: block;
	border-top: 1.6rem solid black;
	padding-top: 1.2rem;

	input:not([type='submit']), textarea {
		margin: 0.6rem 0 9.6rem 0;
		display: block;
		background: $muted-color;
		border: 0;
		width: 100%;
		padding: 0.8rem;

		&:focus {
			background: lighten($muted-color, 5%);
		}
	}

	textarea {
		min-height: 30rem;
		margin-bottom: 4.8rem;
	}

}

input[type='submit']{
	border: 0;
	background: transparent;
	padding: 0;
	display: block;
	width: 100%;
	text-align: left;
	position: relative;
	cursor: pointer;
	z-index: 10;
}

.form-action {
	position: relative;
	&:after {
		content:'';
		width: 5.4rem;
		height: 5.4rem;
		display: block;
		position: absolute;
		top: -0.5rem;
		right: -1.2rem;
		background: transparent url('/assets/back-to-top.svg') no-repeat center center;
		transform: rotate(90deg);
	}
}

.global-footer {
	font-size: 1.6rem;
	padding: 2.4rem 1.2rem;
	width: 100%;
	max-width: 138rem;
	margin: 0 auto;

	@media only screen and (min-width:440px) {
		padding: 2.4rem;
	}

	span {
		display: inline-block;
		color: #aaa;
	}

	a {
		color: #aaa;
		font-weight: bold;
		opacity: 1;
		transition: opacity 0.3s;

		&:hover {
			opacity: 0.5;
		}
	}

}

// Toaster
.toaster {
  display: block;
  background: black;
  z-index:9999;
  position:fixed;
  top:-100%;
  width:100%;
  padding: 2.4rem 1.6rem;
  color: white;
  text-align:center;
  opacity: 0;
  transition: all 0.5s;
	font-size: 1.6rem;

  &.open {
    opacity: 1;
    top: 0;
  }

  &__content {
    display:block;
  }

  &.failed {
    background: red;
    color: white;
  }

  &.thanks {
    background: black;
    color: white;
  }

}



// FitVids solution
.js-reframe {
	margin: 4.8rem 0;
  padding-top: 56.25%;
  position: relative;
  width: 100%; }
  .js-reframe iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
